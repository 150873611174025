var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{ref:"layout",on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(ref){return [_c('b-breadcrumb-item',{attrs:{"text":("Reports - " + (_vm.$store.state.settings.app.current.title))}}),_c('b-breadcrumb-item',{attrs:{"text":"Part Assignment","active":""}})]}},{key:"dropdown-options",fn:function(){return [_c('b-dropdown-item',{on:{"click":_vm.refresh}},[_c('feather-icon',{attrs:{"icon":"RotateCwIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Refresh")])],1)]},proxy:true},{key:"content",fn:function(){return [_c('table-layout',{ref:"table-layout",attrs:{"fields":_vm.table.fields,"filters":_vm.table.filters,"filters-options":{ visible: true, collapsed: false, cols: 3 },"sorting":_vm.table.sorting,"items":_vm.table.items,"loading":_vm.table.loading,"is-searchable":true,"export-exclude-fields":[
                        'id',
                        'ensemble.id',
                        'ensemble.slug',
                        'instrument.id',
                        'instrument.slug' ]},on:{"mounted":function($event){_vm.table = $event},"updated":function($event){_vm.table = $event}},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Ensemble","label-for":"ensemble-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"ensemble-input","options":_vm.options.ensembles.items,"loading":_vm.options.ensembles.loading,"reduce":function (ensemble) { return ensemble.id; },"label":"name","select-on-tab":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.ensemble.value),callback:function ($$v) {_vm.$set(_vm.table.filters.ensemble, "value", $$v)},expression:"table.filters.ensemble.value"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Instrument","label-for":"form-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"form-input","options":_vm.options.instruments.items,"loading":_vm.options.instruments.loading,"reduce":function (instrument) { return instrument.id; },"label":"name","select-on-tab":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.instrument.value),callback:function ($$v) {_vm.$set(_vm.table.filters.instrument, "value", $$v)},expression:"table.filters.instrument.value"}})],1)],1)],1)]},proxy:true},{key:"cell(ensemble.name)",fn:function(ref){
                    var data = ref.data;
return [_c('b-media',{staticClass:"d-print-none",attrs:{"vertical-align":"center","no-body":""}},[_c('b-media-aside',[_c('b-avatar',{attrs:{"button":"","variant":"primary","size":"2.5em","badge-variant":"primary","badge-offset":"-2px"},on:{"click":data.toggleDetails},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('b-icon',{attrs:{"icon":data.item._showDetails === true ? 'chevron-up' : 'chevron-down'}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(data.item.ensemble.code)+" ")])],1),_c('b-media-body',{staticClass:"align-self-center"},[(_vm.hasValue(data, 'item.ensemble.id'))?_c('div',[_vm._v(" "+_vm._s(data.item.ensemble.name)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Ensemble ")])])],1)]}},{key:"row-details",fn:function(ref){
                    var item = ref.item;
return [_c('b-table-lite',{staticClass:"my-75",attrs:{"outlined":"","sticky-header":"","no-border-collapse":"","head-variant":"","primary-key":"id","items":item.items,"fields":[
                          { key: 'index', label: '#', tdClass: ['align-middle']},
                          { key: 'application.student.name', label: 'Student', tdClass: ['align-middle']},
                          { key: 'application.student.grade', label: 'Grade', tdClass: ['align-middle'] },
                          { key: 'ranking.state', label: 'State Rank', tdClass: ['align-middle'] },
                          { key: 'accepted', label: 'Accepted', tdClass: ['align-middle'] },
                          { key: 'row-options', label: '', tdClass: ['align-middle', 'table-row-options'] } ]},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}},{key:"cell(application.student.name)",fn:function(data){return [(_vm.hasValue(data, 'item.application.student.name'))?_c('div',[_vm._v(" "+_vm._s(data.item.application.student.name.first)+" "+_vm._s(data.item.application.student.name.last)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Student ")])]}},{key:"cell(accepted)",fn:function(data){return [_c('b-badge',{attrs:{"variant":_vm.getAcceptedVariant(data.item.accepted)}},[_vm._v(" "+_vm._s(_vm.getAcceptedText(data.item.accepted))+" ")])]}},{key:"cell(row-options)",fn:function(data){return [_c('table-row-options',{attrs:{"index":data.index}},[_c('b-dropdown-item',{attrs:{"to":{ name: 'all-state-student', params: { id: data.item.application.student.id } }}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("View Student")])],1),_c('b-dropdown-item',{attrs:{"to":{ name: 'all-state-application', params: { id: data.item.application.id } }}},[_c('feather-icon',{attrs:{"icon":"ClipboardIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("View Application")])],1)],1)]}}],null,true)})]}},{key:"cell(instrument.name)",fn:function(ref){
                        var data = ref.data;
return [(_vm.hasValue(data, 'item.instrument.id'))?_c('div',[_vm._v(" "+_vm._s(data.item.instrument.name)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Instrument ")])]}},{key:"cell(row-options)",fn:function(ref){
                        var data = ref.data;
return [_c('b-dropdown-item',{attrs:{"to":{ name: 'all-state-selection', params: { slug: data.item.instrument.slug, id: data.item.instrument.id } }}},[_c('feather-icon',{attrs:{"icon":"PlusSquareIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("View Selection")])],1),_c('b-dropdown-item',{attrs:{"to":{ name: 'all-state-acceptance', params: { slug: data.item.ensemble.slug, id: data.item.ensemble.id } }}},[_c('feather-icon',{attrs:{"icon":"CheckSquareIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("View Acceptance")])],1),_c('b-dropdown-divider'),_c('b-dropdown-item-button',{staticClass:"table-row-option-details",on:{"click":data.toggleDetails}},[_c('feather-icon',{attrs:{"icon":("Toggle" + (data.item._showDetails ? 'Left': 'Right') + "Icon")}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Toggle Row Details")])],1)]}}])})]},proxy:true},{key:"debug",fn:function(){return [_c('debug',{attrs:{"collapsed":""}},[_vm._v(_vm._s(_vm.table))])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }