<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Reports - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Part Assignment" active/>
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content>
      <table-layout ref="table-layout"
                    :fields="table.fields"
                    :filters="table.filters"
                    :filters-options="{ visible: true, collapsed: false, cols: 3 }"
                    :sorting="table.sorting"
                    :items="table.items"
                    :loading="table.loading"
                    :is-searchable="true"
                    :export-exclude-fields="[
                        'id',
                        'ensemble.id',
                        'ensemble.slug',
                        'instrument.id',
                        'instrument.slug',
                    ]"
                    @mounted="table = $event"
                    @updated="table = $event"
      >

        <!-- Filters -->
        <template #filters>
          <b-row>
            <b-col cols="12">
                <b-form-group label="Ensemble" label-for="ensemble-input">
                    <v-select id="ensemble-input"
                              v-model="table.filters.ensemble.value"
                              :options="options.ensembles.items"
                              :loading="options.ensembles.loading"
                              :reduce="ensemble => ensemble.id"
                              label="name"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100"/>
                </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Instrument" label-for="form-input">
                <v-select id="form-input"
                          v-model="table.filters.instrument.value"
                          :options="options.instruments.items"
                          :loading="options.instruments.loading"
                          :reduce="instrument => instrument.id"
                          label="name"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100"/>
              </b-form-group>
            </b-col>
          </b-row>
        </template>

				<template #cell(ensemble.name)="{data}">
					<b-media vertical-align="center" no-body class="d-print-none">
						<b-media-aside>
							<b-avatar button
												variant="primary" size="2.5em"
												badge-variant="primary" badge-offset="-2px"
												@click="data.toggleDetails">
								{{ data.item.ensemble.code }}
								<template #badge>
									<b-icon :icon="data.item._showDetails === true ? 'chevron-up' : 'chevron-down'" />
								</template>
							</b-avatar>
						</b-media-aside>
						<b-media-body class="align-self-center">
              <div v-if="hasValue(data, 'item.ensemble.id')">
                {{ data.item.ensemble.name }}
              </div>
              <div v-else class="text-danger">
                No Ensemble
              </div>
						</b-media-body>
					</b-media>
				</template>

				<template #row-details="{item}">
          <b-table-lite outlined sticky-header no-border-collapse head-variant=""
                        primary-key="id"
                        :items="item.items"
                        :fields="[
                          { key: 'index', label: '#', tdClass: ['align-middle']},
                          { key: 'application.student.name', label: 'Student', tdClass: ['align-middle']},
                          { key: 'application.student.grade', label: 'Grade', tdClass: ['align-middle'] },
                          { key: 'ranking.state', label: 'State Rank', tdClass: ['align-middle'] },
                          { key: 'accepted', label: 'Accepted', tdClass: ['align-middle'] },
                          { key: 'row-options', label: '', tdClass: ['align-middle', 'table-row-options'] },
                        ]"
                        class="my-75">
            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>

            <template #cell(application.student.name)="data">
                <div v-if="hasValue(data, 'item.application.student.name')">
                  {{ data.item.application.student.name.first }} {{ data.item.application.student.name.last }}
                </div>
                <div v-else class="text-danger">
                  No Student
                </div>
            </template>


            <!-- Accepted -->
            <template #cell(accepted)="data">
                <b-badge :variant="getAcceptedVariant(data.item.accepted)">
                    {{ getAcceptedText(data.item.accepted) }}
                </b-badge>
            </template>

            <!-- Row Options -->
            <template #cell(row-options)="data">
                <table-row-options :index="data.index">
                    <b-dropdown-item :to="{ name: 'all-state-student', params: { id: data.item.application.student.id } }">
                        <feather-icon icon="UserIcon" />
                        <span class="align-middle ml-50">View Student</span>
                    </b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'all-state-application', params: { id: data.item.application.id } }">
                        <feather-icon icon="ClipboardIcon" />
                        <span class="align-middle ml-50">View Application</span>
                    </b-dropdown-item>
                </table-row-options>
            </template>
          </b-table-lite>
        </template>

        <!-- Instrument -->
        <template #cell(instrument.name)="{data}">
          <div v-if="hasValue(data, 'item.instrument.id')">
            {{ data.item.instrument.name }}
          </div>
          <div v-else class="text-danger">
            No Instrument
          </div>
        </template>

         <!-- Row Actions -->
        <template #cell(row-options)="{data}">
          <b-dropdown-item :to="{ name: 'all-state-selection', params: { slug: data.item.instrument.slug, id: data.item.instrument.id } }">
            <feather-icon icon="PlusSquareIcon" />
            <span class="align-middle ml-50">View Selection</span>
          </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'all-state-acceptance', params: { slug: data.item.ensemble.slug, id: data.item.ensemble.id } }">
                <feather-icon icon="CheckSquareIcon" />
                <span class="align-middle ml-50">View Acceptance</span>
            </b-dropdown-item>
          <b-dropdown-divider/>
          <b-dropdown-item-button class="table-row-option-details" @click="data.toggleDetails">
              <feather-icon :icon="`Toggle${data.item._showDetails ? 'Left': 'Right'}Icon`"/>
              <span class="align-middle ml-50">Toggle Row Details</span>
          </b-dropdown-item-button>
        </template>
      </table-layout>
    </template>

    <template #debug>
      <debug collapsed>{{ table }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import InstrumentModal from '@/views/management/instruments/InstrumentModal.vue'
import avatar from '@/mixins/avatar.mixin';
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import vSelect from 'vue-select'
import { API, graphqlOperation } from 'aws-amplify';
import LastModified from '@/components/LastModified.vue';
import { listSelections, listInstruments, listEnsembles } from '@/graphql/queries/report-part-assignment';
import TableRowOptions from '@/components/TableRowOptions.vue';
import acceptanceMixin from '@/mixins/acceptance.mixin';
import lodashMixin from '@/mixins/lodash.mixin';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  name: 'StateRanking',
  components: {
    TableRowOptions,
    LastModified,
    TableLayout,
    PageLayout,
    InstrumentModal,
    vSelect
  },
  mixins: [ avatar, notify, print, acceptanceMixin, lodashMixin, settingsMixin ],
  data() {
    return {
      table: {
        fields: [
          {
              key: 'ensemble.name',
              label: 'Ensemble',
              sortable: true,
              filterable: true,
              visible: true,
              tdClass: 'align-middle'
          },
          {
            key: 'instrument.name',
            label: 'Instrument',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
              key: 'part',
              label: 'Part',
              sortable: true,
              filterable: true,
              visible: true,
              tdClass: 'align-middle'
          },
          {
              key: 'count',
              label: 'Count',
              sortable: true,
              filterable: true,
              visible: true,
              tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        filters: {
          instrument: { key: 'instrument.id', value: null },
          ensemble: { key: 'ensemble.id', value: null },
        },
        sorting: {
          by: 'id',
          comparator: (a, b) => this.compareByEnsembleNameAndPart(a, b),
          comparatorKey: 'epi',
          comparators: [
            { key: 'epi', text: 'Ensemble, Part, and Instrument', comparator: (a, b) => this.compareByEnsembleNameAndPart(a, b) },
          ]
        },
        items: [],
        loading: true
      },
      options: {
        instruments: {
          items: [],
          loading: true
        },
        ensembles: {
          items: [],
          loading: true
        }
      },
      icon: 'fas fa-music',
      debug: {}
    }
  },
  computed: {
    filterFormOptions() {
      return [...new Set(this.table.items.map(item => item.form?.name || null).filter(name => name !== null))].sort((a, b) => a.localeCompare(b))
    },
    filterEnabledOptions() {
      return [{ label: 'true', value: true }, { label: 'false', value: 'false' }]
    },
  },
  async mounted() {
    this.$refs.layout.state.loading = false
    await this.listInstruments();
    await this.listEnsembles();
    await this.listSelections();
  },
  methods: {
    async refresh() {
      this.table.loading = true
      await this.listSelections()
    },
    async listInstruments() {
      const response = await API.graphql(graphqlOperation(listInstruments));
      this.options.instruments.items = response.data.listInstruments.items.sort((a, b) => (a?.order - b?.order) || a?.name?.localeCompare(b?.name))
      this.options.instruments.loading = false
    },
    async listEnsembles() {
      const response = await API.graphql(graphqlOperation(listEnsembles));
      this.options.ensembles.items = response.data.listEnsembles.items.sort((a, b) => a.name.localeCompare(b.name));
      this.options.ensembles.loading = false
    },
    async listSelections(nextToken, pagedSelections) {
      const selections = pagedSelections || []
      const input = {
        limit: 500,
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start, this.settingsStore.app.current.year.end
            ]
          },
        },
        nextToken: nextToken
      }

      const response = await API.graphql(graphqlOperation(listSelections, input));
      selections.push(...response.data.listSelections.items);

      if(response.data.listSelections.nextToken) {
        await this.listSelections(response.data.listSelections.nextToken, selections)
      }
      else {
        this.table.items = selections;
        this.table.loading = false

        const groupedData = selections.reduce((acc, item) => {
            const ensemble = item?.ensemble || { id: null, name: '', code: '?', slug: 'no-ensemble' };
            const instrument = item?.application?.instrument || { id: null, name: '', order: 0, slug: 'no-instrument' };
            const part = item.part || '';
            const key = `${ensemble.id}_${instrument.id}_${part}`;

            if (!acc[key]) {
                acc[key] = {
                    count: 1,
                    ensemble: ensemble,
                    instrument: instrument,
                    part: part,
                    items: [item]
                };
            }
            else {
                acc[key].count += 1;
                acc[key].items.push(item);
            }
            return acc;
        }, {});

        // Transform the groupedData into a list for b-table
        const tableData = Object.values(groupedData);
        this.table.items = tableData.sort((a, b) => a.instrument.order - b.instrument.order);
        this.table.items.forEach(item => item.items.sort((a, b) => a.ranking.state - b.ranking.state))
      }
    },

    /** Sorting **/
    compareByEnsembleNameAndPart(a, b) {
      return a.ensemble.name.localeCompare(b.ensemble.name)
          || a.part?.localeCompare(b.part, undefined, { numeric: true, sensitivity: 'base' })
          || a.instrument.order - b.instrument.order
    },
  }
}
</script>

<style lang="scss">

</style>
